import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import PageWrapper from 'components/page-wrapper';
import Box from 'components/box';
import Divider from 'components/divider';
import Type from 'components/type';
import Head from 'components/head';
import ContactFormik from 'components/contact-formik';

const Contact = ({ data }) => (
  <PageWrapper bg="bg-info select-infoBright" withAlert>
    <Head pageTitle="Contact" />
    <Box
      width="max-w-full pt-9 sm:pt-14 px-9 sm:px-14"
      flex="flex flex-col items-center"
    >
      <Box width="pt-14 px-6 md:px-16 pb-14 max-w-xl xl:max-w-2xl">
        <Type align="text-center" size="large">
          {documentToReactComponents(data.contentfulContactPage.headline.json)}
        </Type>
        <Divider />
        <ContactFormik />
      </Box>
    </Box>
  </PageWrapper>
);

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;

export const query = graphql`
  query ContactQuery {
    contentfulContactPage {
      id
      headline {
        id
        json
      }
    }
  }
`;
